<template>
  <div class="page">
    <div class="main-title" @click="goPath('teachersAndStudentsMoreList', 1)">
      <div class="title-l">师资介绍</div>
      <div class="title-c">更多</div>
      <img class="title-r" src="./img/arrow.png" alt="" />
    </div>
    <div class="list">
      <div class="item" v-for="(item, index) in list1" :key="index">
        <div class="item-top">
          <img :src="item.photo" alt="" />
        </div>
        <div class="item-bottom">
          {{ item.introduce }}
        </div>
      </div>
    </div>
    <div class="main-title" @click="goPath('teachersAndStudentsMoreList', 2)">
      <div class="title-l">优秀学员</div>
      <div class="title-c">更多</div>
      <img class="title-r" src="./img/arrow.png" alt="" />
    </div>
    <div class="list">
      <div class="item" v-for="(item, index) in list2" :key="index">
        <div class="item-top">
          <img :src="item.photo" alt="" />
        </div>
        <div class="item-bottom">
          {{ item.introduce }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tsIntroductionList } from "./api.js";
export default {
  data() {
    return {
      list1: [],
      list2: [],
    };
  },
  mounted() {
    this.getList1();
    this.getList2();
  },
  methods: {
    getList2() {
      let params = {
        type: 2,
        curPage: 1,
        pageSize: 4,
      };
      this.$axios
        .get(`${tsIntroductionList}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.list2 = res.data.records;
            this.list2.forEach((ele) => {
              if (ele.photo) {
                ele.photo = this.$handleImg(336, 280, ele.photo);
              }
            });
          }
        });
    },
    getList1() {
      let params = {
        type: 1,
        curPage: 1,
        pageSize: 4,
      };
      this.$axios
        .get(`${tsIntroductionList}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.list1 = res.data.records;
            this.list1.forEach((ele) => {
              if (ele.photo) {
                ele.photo = this.$handleImg(336, 280, ele.photo);
              }
            });
          }
        });
    },
    goPath(name, type) {
      this.$router.push({
        name: name,
        query: {
          type: type,
        },
      });
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 30px;
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 30px 0 60px;
    .item {
      width: 336px;
      min-height: 508px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
      border-radius: 16px;
      overflow: hidden;
      margin-bottom: 20px;
      .item-bottom {
        box-sizing: border-box;
        height: calc(100% - 280px);
        padding: 16px 20px;
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 44px;
      }
      .item-top {
        width: 100%;
        height: 280px;
        background: #000;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .main-title {
    display: flex;
    align-items: center;
    .title-r {
      width: 14px;
      height: 22px;
      margin-left: 12px;
    }
    .title-c {
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
    }
    .title-l {
      font-size: 36px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      flex: 1;
    }
  }
}
</style>